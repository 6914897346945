export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  {
    title: '库位编号',
    dataIndex: 'location_number',
    key: 'location_number',
  },
  {
    title: '批次编号',
    dataIndex: 'batch_number',
    key: 'batch_number',
  },
  {
    title: '产品名称',
    dataIndex: 'material_name',
    key: 'material_name',
    width: 300
  },
  {
    title: '产品英文名称',
    dataIndex: 'material_english_name',
    key: 'material_english_name',
    width: 300
  },
  {
    title: '产品编号',
    dataIndex: 'material_number',
    key: 'material_number',
  },
  {
    title: '账面数量',
    key: 'book_quantity',
    dataIndex: 'book_quantity',
  },
  {
    title: '已分配数量',
    dataIndex: 'assigned_quantity',
  },
  {
    title: '可分配数量',
    dataIndex: 'assignable_quantity',
  },
  {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: '包装规格',
    dataIndex: 'material_spec',
    key: 'material_spec',
  },
]