<template>
  <div>
    <a-card title="盘点单详情">
    <!-- <a-button slot="extra" type="primary" style="margin-right: 8px;" ghost v-print="'#printContent'"> <a-icon type="printer" />打印</a-button> -->
    <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />返回</a-button>
      <section id="printContent">
        <a-spin :spinning="orderLoading">
          <img class="jsbarcode" id="jsbarcode" ref="jsbarcode"/>
          <a-descriptions bordered>
            <a-descriptions-item label="盘点单号">
              {{ stockCheckOrderItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ stockCheckOrderItem.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="账面总数量">
              {{ stockCheckOrderItem.total_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ stockCheckOrderItem.remark }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>

        <div style="margin-top: 16px;">
          <a-table rowKey="id" size="small" :columns="columns" :data-source="stockCheckMaterialItems"
            :loading="materialLoading" :pagination="false">
          </a-table>
        </div>
      </section>
    </a-card>
  </div>
</template>

<script>
  import JsBarcode from 'jsbarcode'
  import { stockCheckOrderRetrieve, stockCheckOrderMaterials } from '@/api/stockCheck';
  import { columns } from './columns';

  export default {
    data() {
      return {
        columns,
        orderLoading: false,
        materialLoading: false,
        stockCheckOrder: undefined,
        stockCheckOrderItem: {},
        stockCheckMaterialItems: [],
      }
    },
    methods: {
      initData() {
        this.stockCheckOrder = this.$route.query.id;
        this.orderLoading = true;
        document.getElementById('jsbarcode').html = '';
        stockCheckOrderRetrieve({ id: this.stockCheckOrder }).then(data => {
          this.stockCheckOrderItem = data;
          var jsbarcode = new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: 'CODE128',
            width: 2,
            height: 35,
            displayValue:true,
            background: '#ffffff',
            lineColor: '#000000'
          })
        }).finally(() => {
          this.orderLoading = false;
        });

        this.materialLoading = true;
        stockCheckOrderMaterials({ id: this.stockCheckOrder }).then(data => {
          this.stockCheckMaterialItems = data;
        }).finally(() => {
          this.materialLoading = false;
        });
      },
    },
    mounted() {
      this.initData();
    },
  }
</script>
<style>
  .jsbarcode {
    display: inline-block;
    float: right;
  }
</style>